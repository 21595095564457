@tailwind base;
@tailwind components;
@tailwind utilities;





.count {
  font-size: 2rem;
  font-weight: 700;
  min-width: 56px;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #98ffc3;
  border-radius: 6px;
}
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
}

.dialog-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.dialog-close::before,
.dialog-close::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  background-color: #333;
}

.dialog-close::before {
  transform: rotate(45deg);
}

.dialog-close::after {
  transform: rotate(-45deg);
}

.dialog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.dialog-content p {
  margin-bottom: 10px;
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.dialog-actions button {
  margin-left: 10px;
}


h2{
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.MuiFormControl-root{
  /* flex-shrink: 0!important; */
}



.rug-card{
  background: white!important;
}

.rug-card .rug-card-name{
  background: none!important;
  text-shadow: 1px 1px 2px black;
}

